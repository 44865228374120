import classNames from "classnames";
import { Text } from "@components/Text";
import type { UseCommonData_UsersTeamFragment } from "@hooks/useCommonData/__generated__/fragments";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";
import type { FC } from "react";

type FavoriteTeamModalIconProps = {
  team: UseCommonData_UsersTeamFragment;
  selectionIndex: number;
  onClick: (teamId: number) => void;
};

export const FavoriteTeamModalIcon: FC<FavoriteTeamModalIconProps> = ({
  team,
  selectionIndex,
  onClick,
}) => {
  const isSelected = selectionIndex >= 0;
  return (
    <div
      className={classNames(
        styles.favoriteTeamListItem,
        isSelected ? styles.selected : null
      )}
      key={team.id}
      onClick={() => onClick(team.id)}
    >
      <div className={styles.favoriteTeamLogo}>
        <img src={IFImg(team.iconPath)} alt="" />
        {isSelected && (
          <i className={styles.favoriteTeamSelected}>{selectionIndex + 1}</i>
        )}
      </div>
      <Text size={12} className={styles.favoriteTeamListItemText}>
        {team.shortName}
      </Text>
    </div>
  );
};
