import { useEffect, useMemo, useState } from "react";
import { CommonDataState } from "@hooks/useCommonData";
import type { UseCommonData_UsersTournamentFragment } from "@hooks/useCommonData/__generated__/fragments";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { CategoryTab } from "@libs/utils/teams";
import { getCategorizedTabs } from "@libs/utils/teams";

type TeamTabsState = {
  selectedTabIndex: number;
  currentTournament: UseCommonData_UsersTournamentFragment | undefined;
  categoryTabs: CategoryTab[];
  teamsByCurrentTournament: UseCommonData_CommonDataQuery["teamsUnbundled"];
  changeTournament: (tabIndex: number, tournamentId: number) => void;
};

const useFavoriteTargatTeamTabs = (): TeamTabsState => {
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [currentTournamentId, setCurrentTournamentId] = useState(0);
  const { data: commonData, loading: allTeamsLoading } =
    CommonDataState.useContainer();

  // CommonDataがロードし終わったらUIタブ選択を初期化する
  useEffect(() => {
    if (!allTeamsLoading) {
      const targetTournaments =
        commonData?.tournaments?.filter((t) => t.isFavoriteTarget) || [];
      setCurrentTournamentId(targetTournaments[0].id);
    }
  }, [allTeamsLoading]);

  const allTeams = commonData?.teamsUnbundled || [];
  const targetTournaments =
    commonData?.tournaments?.filter((t) => t.isFavoriteTarget) || [];

  // 「お気に入り編集」「チームから探す」のような、季節イベントに左右されない常に表示するチーム情報をトーナメントカテゴリごとにタブ切り替えできるようにしたデータ
  // リーグの選択時に、カテゴリタブ別のリーグの選択状況を記録するために（=previouslySelectedTournamentId）、stateが更新されても初期化されないようにメモ化している
  const categoryTabs: CategoryTab[] = useMemo(
    () => getCategorizedTabs(targetTournaments),
    [allTeamsLoading]
  );

  const teamsByCurrentTournament = useMemo(() => {
    return (
      allTeams.filter(
        (team) =>
          team.masterTournaments?.some(
            (tournament) => tournament.id === currentTournamentId
          )
      ) ?? []
    );
  }, [allTeams, currentTournamentId]);

  const currentTournament = targetTournaments.find(
    (t) => t.id === currentTournamentId
  );

  const changeTournament = (tabIndex: number, tournamentId: number) => {
    categoryTabs[selectedTabIndex].previouslySelectedTournamentId =
      currentTournamentId;

    setTabIndex(tabIndex);
    setCurrentTournamentId(tournamentId);
  };

  return {
    selectedTabIndex,
    currentTournament,
    teamsByCurrentTournament,
    categoryTabs,
    changeTournament,
  };
};

export { useFavoriteTargatTeamTabs };
