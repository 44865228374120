import { useEffect, useState } from "react";
import type { UseCommonData_UsersTeamFragment } from "@hooks/useCommonData/__generated__/fragments";

export const useFavoriteTeamModalSelection = (props: {
  initialSelectedTeams: UseCommonData_UsersTeamFragment[] | undefined;
  maxSelectedTeams: number;
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const clickHandler = (teamId: number) => {
    if (selectedIds.includes(teamId)) {
      setSelectedIds(selectedIds.filter((id) => id !== teamId));
      return;
    }

    if (selectedIds.length >= props.maxSelectedTeams) {
      return;
    }

    setSelectedIds([...selectedIds, teamId]);
  };

  const clear = () => {
    setSelectedIds([]);
  };

  const isClearable = selectedIds.length > 0;

  useEffect(() => {
    if (selectedIds.length > 0) {
      return;
    }
    setSelectedIds(props.initialSelectedTeams?.map((t) => t.id) ?? []);
  }, [props.initialSelectedTeams]);

  return {
    selectedIds,
    clickHandler,
    clear,
    isClearable,
  };
};
