import { useState, useEffect } from "react";
import useToggleScroll from "@hooks/useToggleScroll";

export const useFavoriteTeamModalControl = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const toggleScroll = useToggleScroll.useContainer();
  useEffect(() => {
    (isModalOpened ? toggleScroll.disable : toggleScroll.enable)();
  }, [isModalOpened]);

  const openModal = () => setIsModalOpened(true);

  // モーダルを閉じる際にモーダルのコンポーネントをその場で消すと閉じるアニメーションが発生しないため
  // アニメーションが完了してから消す
  const [willModalClose, setWillModalClose] = useState(false);
  const modalCloseHandler = () => {
    setWillModalClose(true);
  };
  const modalCloseAnimationEndHandler = () => {
    setIsModalOpened(false);
    setWillModalClose(false);
  };

  return {
    needsMount: isModalOpened,
    open: openModal,
    handlers: {
      onClose: modalCloseHandler,
      onCloseAnimationEnd: modalCloseAnimationEndHandler,
      willClose: willModalClose,
    },
  };
};
