import { useEffect, useMemo, useRef, useState } from "react";
import type { UseCommonData_UsersTeamFragment } from "@hooks/useCommonData/__generated__/fragments";
import styles from "./index.module.css";

export const useSizeAdjuster = (
  teamsToDisplay: UseCommonData_UsersTeamFragment[]
) => {
  const [lineLength, setLineLength] = useState(0);
  const teamList = useRef<HTMLDivElement | null>(null);
  const resizeHandler = () => {
    if (teamList.current === null) {
      return;
    }
    const iconElements = Array.from(teamList.current.children) as HTMLElement[];
    if (iconElements.length <= 0) {
      return;
    }

    // 最初の要素と同じ `offsetTop` の値を持つ要素の数を1行の長さとする
    const length = iconElements.filter(
      (i) => i.offsetTop === iconElements[0].offsetTop
    ).length;
    setLineLength(length);
  };
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  });

  const spacers = useMemo(() => {
    if (lineLength === 0) {
      return null;
    }
    const requiredSpacers = lineLength - (teamsToDisplay.length % lineLength);
    return new Array(requiredSpacers)
      .fill(0)
      .map((_, i) => <div className={styles.favoriteTeamListItem} key={i} />);
  }, [lineLength, teamsToDisplay]);
  return {
    teamList,
    spacers,
  };
};
