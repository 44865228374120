import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import type React from "react";

export const useSwipeClose = (props: {
  onClose: () => void;
  enabled: boolean;
  scrollContentRef?: React.MutableRefObject<null> | null;
}) => {
  const CLOSE_SWIPE_THRESHOLD = 66;

  type SwipeStatus = "scrolling" | "closing" | "none";
  const [swipeStatus, setSwipeStatus] = useState<SwipeStatus>("none");

  const [feedbackDistance, setFeedbackDistance] = useState(0);

  const handlers = useSwipeable({
    trackMouse: false, // MouseとTouchの両方を有効にするとバグるので一旦Mouseを無効にしている
    trackTouch: true,
    onSwiping: (e) => {
      if (!props.enabled) {
        return;
      }

      const scrolls = (() => {
        if (
          props.scrollContentRef === null ||
          props.scrollContentRef === undefined
        ) {
          return false;
        }

        const element = props.scrollContentRef.current as HTMLElement | null;
        if (element === null) {
          return false;
        }
        if (!element.contains(e.event.target as HTMLElement)) {
          return false;
        }

        return element.scrollTop > 0;
      })();

      if (swipeStatus === "scrolling") {
        return;
      }

      if (e.dir !== "Down" || scrolls) {
        setSwipeStatus("scrolling");
        return;
      }

      setFeedbackDistance(e.deltaY);
      setSwipeStatus("closing");
    },
    onSwiped: (e) => {
      if (!props.enabled) {
        return;
      }

      const willClose =
        e.dir === "Down" &&
        swipeStatus === "closing" &&
        e.deltaY > CLOSE_SWIPE_THRESHOLD;

      if (!willClose) {
        setSwipeStatus("none");
        setFeedbackDistance(0);
        return;
      }

      props.onClose();
    },
  });

  return { feedbackDistance, handlers };
};
