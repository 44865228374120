import { useEffect, useState } from "react";
import useIsPc from "@hooks/useIsPc";
import { checkIsPcByScreenWidth } from "@libs/utils/browser";
import styles from "./index.module.css";

export const useAnimation = (
  willClose: boolean,
  onCloseAnimationEnd: () => void
) => {
  const isPc = useIsPc.useContainer();
  const [animationStyle, setAnimationStyle] = useState<string | null>(
    checkIsPcByScreenWidth() ? null : styles.slideIn
  );
  const [overlayAnimationStyle, setOverlayAnimationStyle] = useState<
    string | null
  >(null);
  const animationEndHandler = () => {
    if (willClose) {
      onCloseAnimationEnd();
    } else {
      setAnimationStyle(null);
    }
  };

  useEffect(() => {
    if (willClose) {
      setAnimationStyle(isPc ? styles.fadeOut : styles.slideOut);
      setOverlayAnimationStyle(styles.fadeOut);
    }
  }, [willClose]);

  return {
    animationStyle,
    overlayAnimationStyle,
    animationEndHandler,
  };
};
