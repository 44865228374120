import classNames from "classnames";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { FavoriteTeamModal } from "@components/FavoriteTeamModal";
import { Text } from "@components/Text";
import Toaster from "@components/Toaster";
import Auth from "@hooks/useAuth";
import Message, { PredefinedMessages } from "@hooks/useMessage";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import { isOfflineError } from "@libs/apolloClient";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import { useFavoriteTeam_UpdateFavoriteTeamsMutation as useUpdateFavoriteTeamsMutation } from "./__generated__/mutations";
import { useFavoriteTeamModalControl } from "./_useFavoriteTeamModalControl";
import styles from "./index.module.css";
import type { FavoriteTeam_UsersTeamFragment as UsersTeamFragment } from "./__generated__/fragments";
import type { FC } from "react";

type PageType = "top" | "mypage";

type FavoriteTeamProps = {
  pageType: PageType;
};

export const FavoriteTeam: FC<FavoriteTeamProps> = ({ pageType }) => {
  const { fanstaUser, isFanstaUserRegisteredAndValidated, loadFanstaUser } =
    Auth.useContainer();
  const teamsByTournamentState = TeamsByTournamentState.useContainer();

  const displays = useMemo(() => {
    if (!isFanstaUserRegisteredAndValidated) {
      return false;
    }

    if (teamsByTournamentState.allTeamsLoading) {
      return false;
    }

    return true;
  }, [fanstaUser, isFanstaUserRegisteredAndValidated, teamsByTournamentState]);

  const modalControl = useFavoriteTeamModalControl();

  const [favoriteTeams, setFavoriteTeams] = useState<UsersTeamFragment[]>([]);

  const findTeam = (id: number) => {
    const team = teamsByTournamentState.allTeams.find((t) => t.id === id);
    if (team === undefined) {
      throw new Error("Unknown team id");
    }
    return team;
  };

  useEffect(() => {
    const isReady =
      isFanstaUserRegisteredAndValidated &&
      !teamsByTournamentState.allTeamsLoading;
    if (isReady && favoriteTeams.length <= 0) {
      const teams = fanstaUser?.currentUser.user?.favoriteTeams.map((t) =>
        findTeam(t.id)
      );
      setFavoriteTeams(teams ?? []);
    }
  }, [fanstaUser, isFanstaUserRegisteredAndValidated, teamsByTournamentState]);

  const { addMessage } = Message.useContainer();

  const [updateFavoriteTeams] = useUpdateFavoriteTeamsMutation();

  const appliedHandler = async (teamIds: number[]): Promise<boolean> => {
    try {
      const result = await updateFavoriteTeams({
        variables: {
          teamIds,
        },
      });

      if (result.errors) {
        return false;
      }
      GTMEvent(GTMEvents.favoriteTeamSaved(teamIds));
      setFavoriteTeams(result.data?.updateFavoriteTeams?.favoriteTeams ?? []);
      loadFanstaUser && loadFanstaUser();
      return true;
    } catch (err) {
      isOfflineError(err)
        ? addMessage(PredefinedMessages.offlineError)
        : addMessage(PredefinedMessages.commonError);
      return false;
    }
  };

  const PageTypeClass = pageType === "top" ? [styles.topPage] : [styles.myPage];

  const title: { [key in PageType]: React.ReactElement } = {
    top: (
      <h2 className="c-contentTitle c-contentTitle--fz24">お気に入りチーム</h2>
    ),
    mypage: (
      <Text className={styles.contentTitle} size={16} bold>
        お気に入りチーム
      </Text>
    ),
  };

  if (!displays && pageType === "top") {
    return null;
  }

  const teamIconHref = (team: UsersTeamFragment) =>
    !team.masterSportGenre.slug || !team.slug
      ? paths.home
      : paths.team(team.masterSportGenre.slug, team.slug);

  return (
    <>
      {modalControl.needsMount && (
        <FavoriteTeamModal
          {...modalControl.handlers}
          initialFavoriteTeams={favoriteTeams}
          onApplied={appliedHandler}
        />
      )}
      <Toaster
        messageFilter={[
          PredefinedMessages.offlineError,
          PredefinedMessages.commonError,
        ]}
      />

      <div className={styles.favoriteTeamArea}>
        <div
          className={classNames(styles.favoriteTeamAreaTitle, PageTypeClass)}
        >
          {title[pageType]}
          {favoriteTeams.length > 0 && (
            <button
              type="button"
              className={styles.editButton}
              onClick={modalControl.open}
            >
              <Text size={15}>編集する</Text>
            </button>
          )}
        </div>

        <div
          className={classNames(
            styles.favoriteTeamList,
            PageTypeClass,
            teamsByTournamentState.allTeamsLoading ? styles.invisible : ""
          )}
        >
          {favoriteTeams.length > 0 ? (
            favoriteTeams.map((team) => (
              <Link
                href={teamIconHref(team)}
                key={team.id}
                className={styles.favoriteTeamListItem}
              >
                <div className={styles.favoriteTeamListItemLogo}>
                  <img src={IFImg(team.iconPath)} alt="" />
                </div>
                <Text size={14} className={styles.favoriteTeamListItemText}>
                  {team.shortName}
                </Text>
              </Link>
            ))
          ) : (
            <div className={styles.favoriteTeamListItem}>
              <button
                onClick={modalControl.open}
                type="button"
                className={styles.addButton}
              ></button>
              <Text size={12} className={styles.favoriteTeamListItemText}>
                追加する
              </Text>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavoriteTeam;
