import classNames from "classnames";
import { useRef, useState } from "react";
import * as React from "react";
import { Button } from "@components/Button";
import { useAnimation } from "@components/FavoriteTeamModal/_useAnimation";
import { useSizeAdjuster } from "@components/FavoriteTeamModal/_useSizeAdjuster";
import { Text } from "@components/Text";
import type { UseCommonData_UsersTeamFragment } from "@hooks/useCommonData/__generated__/fragments";
import { useFavoriteTargatTeamTabs } from "@hooks/useFavoriteTargatTeamTabs";
import useIsPc from "@hooks/useIsPc";
import { FavoriteTeamModalIcon } from "./_FavoriteTeamModalIcon";
import { FavoriteTeamModalTournament } from "./_FavoriteTeamModalTournament";
import { useFavoriteTeamModalSelection } from "./_useFavoriteTeamModalSelection";
import { useSwipeClose } from "./_useSwipeClose";
import styles from "./index.module.css";

type FavoriteTeamModalProps = {
  onClose: () => void;
  onCloseAnimationEnd: () => void;
  onApplied: (teamIds: number[]) => Promise<boolean>;
  willClose: boolean;
  initialFavoriteTeams: UseCommonData_UsersTeamFragment[] | undefined;
};

export const MAX_FAVORITE_TEAMS = 10;

export const FavoriteTeamModal: React.FC<FavoriteTeamModalProps> = (props) => {
  const isPc = useIsPc.useContainer();
  const favoriteTeamListRef = useRef(null);
  const swipeClose = useSwipeClose({
    onClose: props.onClose,
    enabled: !isPc,
    scrollContentRef: favoriteTeamListRef,
  });
  const { animationStyle, overlayAnimationStyle, animationEndHandler } =
    useAnimation(props.willClose, props.onCloseAnimationEnd);

  const feedbackStyle = {
    "--delta-y": swipeClose.feedbackDistance + "px",
  } as React.CSSProperties;

  const {
    selectedTabIndex,
    currentTournament,
    teamsByCurrentTournament,
    categoryTabs,
    changeTournament,
  } = useFavoriteTargatTeamTabs();
  const selectedCategory = categoryTabs[selectedTabIndex];

  const { teamList, spacers } = useSizeAdjuster(teamsByCurrentTournament);

  const selection = useFavoriteTeamModalSelection({
    initialSelectedTeams: props.initialFavoriteTeams,
    maxSelectedTeams: MAX_FAVORITE_TEAMS,
  });

  const [isSaving, setIsSaving] = useState(false);

  const saveHandler = async () => {
    setIsSaving(true);
    // 更新が成功したらモーダルを閉じる
    const result = await props.onApplied(selection.selectedIds);
    if (result) {
      props.onClose();
    }
    setIsSaving(false);
  };

  return (
    <div className={styles.modalWrapper} {...swipeClose.handlers}>
      <div
        className={classNames(styles.modalOverlay, overlayAnimationStyle)}
        onClick={props.onClose}
      />
      <div className={styles.modalInner}>
        <button
          type="button"
          className={classNames(styles.modalClose, animationStyle)}
          onClick={props.onClose}
          onAnimationEnd={animationEndHandler}
        ></button>
        <div
          className={classNames(styles.modalContent, animationStyle)}
          style={feedbackStyle}
          onAnimationEnd={animationEndHandler}
        >
          <div className={classNames(styles.modalTopBar, "md:none")}></div>
          <div className={styles.favoriteTeamHeader}>
            {categoryTabs.map((ct, i) => (
              <div
                key={ct.label}
                className={classNames(styles.favoriteTeamHeaderItem, {
                  [styles.isActive]: selectedTabIndex === i,
                })}
                onClick={() => {
                  changeTournament(i, ct.previouslySelectedTournamentId);
                }}
              >
                <span>{ct.label}</span>
              </div>
            ))}
          </div>
          {selectedCategory && selectedCategory.tournamentTabs.length > 1 && (
            <div className={styles.favoriteTeamTab}>
              {selectedCategory.tournamentTabs.map((t) => (
                <FavoriteTeamModalTournament
                  key={t.id}
                  tournament={t}
                  isSelected={t.id === currentTournament?.id}
                  onClick={() => changeTournament(selectedTabIndex, t.id)}
                />
              ))}
            </div>
          )}
          <div className={styles.favoriteTeamList} ref={favoriteTeamListRef}>
            {currentTournament?.shouldShowDazn && (
              <div className={styles.copyright}>
                <img
                  className={styles.logoDAZN}
                  src="/img/logo_dazn.svg"
                  alt=""
                />
                Powered by DAZN
              </div>
            )}

            <div className={styles.favoriteTeamLeadText}>
              <Text size={12}>
                お気に入りチームは最大{MAX_FAVORITE_TEAMS}
                チームまで追加できます。
              </Text>
            </div>

            <div className={styles.favoriteTeamListContent} ref={teamList}>
              {teamsByCurrentTournament.map((t) => (
                <FavoriteTeamModalIcon
                  key={t.id}
                  team={t}
                  onClick={selection.clickHandler}
                  selectionIndex={selection.selectedIds.indexOf(t.id)}
                />
              ))}
              {spacers}
            </div>

            <div className={styles.favoriteTeamButtonBlock}>
              <Button
                className={classNames(styles.button, styles.clearButton)}
                disabled={!selection.isClearable}
                onClick={selection.clear}
                styleType="filled"
                colorType="secondary"
              >
                <Text size={15} bold>
                  クリア
                </Text>
              </Button>
              <Button
                className={classNames(styles.button, styles.addButton)}
                styleType="filled"
                colorType="primary"
                onClick={saveHandler}
                disabled={isSaving}
              >
                <Text size={15} bold>
                  保存する
                </Text>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteTeamModal;
