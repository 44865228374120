import classNames from "classnames";
import type { TournamentTab } from "@libs/utils/teams";
import styles from "./index.module.css";
import type { FC } from "react";

type FavoriteTeamModalTournamentProps = {
  tournament: TournamentTab;
  isSelected: boolean;
  onClick: (tournamentId: number) => void;
};

export const FavoriteTeamModalTournament: FC<
  FavoriteTeamModalTournamentProps
> = ({ tournament, isSelected, onClick }) => {
  return (
    <button
      type="button"
      className={classNames(
        styles.favoriteTeamTabItem,
        isSelected ? styles.active : null
      )}
      key={tournament.id}
      onClick={() => onClick(tournament.id)}
    >
      <span>{tournament.displayName}</span>
    </button>
  );
};
